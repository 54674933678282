import generalActionTypes from './general.type';

const INITIAL_STATE = {
  sideBarShow: false,
  reloadManagerDashBoard: false,
};

const generalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case generalActionTypes.SET_SIDEBAR:
      return { ...state, sideBarShow: action.payload };
    case generalActionTypes.RELOAD_MANAGER_DASHBOARD:
      return { ...state, reloadManagerDashBoard: !state.reloadManagerDashBoard };
    default:
      return state;
  }
};

export default generalReducer;
