import React, { useState } from 'react';
import * as Yup from 'yup';
import eyePasswordShow from '../../images/eye-password-show.svg';
import eyePasswordHide from '../../images/eye-password-hide.svg';
import SiteLogo from '../../images/goals-new-logo.svg';
import TextInput from '../../components/common/input-field';
import { Formik, Form } from 'formik';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Processor from '../../components/processor/processor';
import { changePasswordWithLink } from '../../services/authentication.services';

const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

const ForgotPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const location = useLocation();
  const { token } = useParams();
  const [passwordChangedSuccessFully, setPasswordChangedSuccessFully] = useState(false);

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        passwordRegExp,
        'Password must be 8 characters long with at least 1 uppercase character, 1 lowercase character, 1 special character and 1 number\n'
      )
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Password confirmation is required'),
  });

  const submitFunction = async (value) => {
    setLoading(true);
    let res = await changePasswordWithLink({ password: value.password, token });
    if (res.data.response_type === 'success') {
      // history.push('/login');
      setPasswordChangedSuccessFully(true);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="login_header">
        <div className="container">
          <div className="login_header-inner">
            <div className="login_header_left">
              <div className="login_form_logo cursor">
                <a href={process.env.REACT_APP_DASHBOARD_URL ? process.env.REACT_APP_DASHBOARD_URL : '#!'}>
                  <img src={SiteLogo} alt="" width={115} />
                </a>
              </div>
            </div>
            <div className="login_header_right">
              <div className="login_header_right_inner">
                <p className="login_header_right_txt">Already have an account?</p>
                <button className="btn_blue-login" onClick={() => history.push('/login')}>
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="loginRegister-wrap">
        <div className="forgotBox">
          <div className={`card_design authBox forgotForm ${passwordChangedSuccessFully ? 'flex' : ''}`}>
            {passwordChangedSuccessFully ? (
              <div className="success_msg">
                <p>Your password reset successfully.</p>
                <p>
                  <a href={`${process.env.REACT_APP_APP_URL}/login`}>Click Here</a> to login.
                </p>
              </div>
            ) : (
              <>
                <h3>{location.pathname.includes('set-password') ? 'Set' : 'Reset'} Password</h3>
                <div className="signup-form-wrapper">
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(value) => {
                      submitFunction(value);
                    }}
                  >
                    {({ values }) => (
                      <Form className="inner_form_wrapper">
                        <div className="cs_form_row hsPassword">
                          <TextInput
                            label="New Password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={values.password}
                            withFocus={true}
                          />
                          <img
                            className="hsImg "
                            src={showPassword ? eyePasswordHide : eyePasswordShow}
                            onClick={() => setShowPassword(!showPassword)}
                            alt=""
                          />
                        </div>
                        <div className="cs_form_row hsPassword">
                          <TextInput
                            label="Confirm Password"
                            name="confirmPassword"
                            type={showPassword2 ? 'text' : 'password'}
                            value={values.confirmPassword}
                            withFocus={true}
                          />
                          <img
                            className="hsImg "
                            src={showPassword2 ? eyePasswordHide : eyePasswordShow}
                            onClick={() => setShowPassword2(!showPassword2)}
                            alt=""
                          />
                        </div>

                        <div className="creatAC-wrapper">
                          <button
                            className={`create-account-btn ${loading ? 'loader_active' : ''}`}
                            disabled={loading}
                            type="submit"
                          >
                            {loading && <Processor />}
                            Submit
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
