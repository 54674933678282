import NotificationActionTypes from './notification.type';

export const setNotificationCount = (count) => ({
  type: NotificationActionTypes.SET_NOTIFICATION_COUNT,
  payload: count,
});
export const setNotificationDiscussion = (count) => ({
  type: NotificationActionTypes.SET_NOTIFICATION_DISCUSSION,
  payload: count,
});
export const setNotificationHighlights = (count) => ({
  type: NotificationActionTypes.SET_NOTIFICATION_HIGHLIGHT,
  payload: count,
});

export const setNotificationHelpChat = (count) => ({
  type: NotificationActionTypes.SET_NOTIFICATION_HELPCHAT,
  payload: count,
});
