import AdminActionTypes from './admin.type';

const INITIAL_STATE = {
  token: null,
  user: null,
};

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AdminActionTypes.SET_ADMIN_USER:
      return { ...state, user: action.payload };
    case AdminActionTypes.SET_ADMIN_TOKEN:
      return { ...state, token: action.payload };
    default:
      return state;
  }
};

export default adminReducer;
