import IntroPopUpActionTypes from './IntroPopUp.type';

const INITIAL_STATE = {
  Welcome: false,
  InviteSalesTeam: false,
  TransactionHistory: false,
  ROICalculations: false,
};

const introPopUpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IntroPopUpActionTypes.SET_INTRO_POPUP:
      return { ...action.payload };
    default:
      return state;
  }
};

export default introPopUpReducer;
