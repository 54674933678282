import AdminActionTypes from './admin.type';

export const setAdminUser = (user) => ({
  type: AdminActionTypes.SET_ADMIN_USER,
  payload: user,
});
export const setAdminToken = (user) => ({
  type: AdminActionTypes.SET_ADMIN_TOKEN,
  payload: user,
});
