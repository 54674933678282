import { axiosPost, axiosGet } from '../axios/config';

const prefix = '/admin/chat/';

export const getAdminCurrentUser = () => {
  return axiosGet('/admin/profile');
};

export const getAdminImage = () => {
  return axiosGet('/admin/profile-image');
};

export const updateAdminImage = (data) => {
  return axiosPost('/admin/profile-image', data);
};

export const adminUsersDelete = (data) => {
  return axiosPost('/admin/users', data);
};

export const adminUsersUpdate = (data) => {
  return axiosPost('/admin/users-update', data);
};

export const getTokenForAdmin = (data) => {
  return axiosGet('/admin/get-token', data);
};

export const getCompanyList = (data) => {
  return axiosGet('/admin/company-list', data);
};

export const helpNotificationCheck = (user) => {
  return axiosGet('/admin/notification/help', user);
};

export const removeNotificationHighlightChat = async (data) => {
  const response = await axiosPost('/admin/remove-notification-highlight-chat', data);
  return response;
};

export const getAdminHelpRoom = () => {
  return axiosGet(prefix + 'get-rooms');
};

export const getAdminChatMessages = (data) => {
  return axiosGet(prefix + 'get-room-messages', data);
};

export const AdminImageUpload = (data) => {
  return axiosPost(prefix + 'image-upload', data);
};

export const deleteAdminRoomChat = (data) => {
  return axiosPost(prefix + 'delete-room-chat', data);
};
