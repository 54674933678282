import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';

const TextInput = (props) => {
  const { label, name, type, withFocus = false, value = null, ...rest } = props;
  const [focus, setFocus] = useState(false);
  return (
    <>
      {withFocus ? null : <label className="cs_form_label">{label}</label>}
      <div className="extra_icon_wrap">
        <Field
          className={withFocus && (focus || value) ? 'fcw_value' : ''}
          type={type}
          name={name}
          {...rest}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {withFocus ? <label className="cs_form_label">{label}</label> : null}
        <ErrorMessage name={name}>
          {(msg) => (
            <span className="fm_error" style={{ color: 'red' }}>
              {msg}
            </span>
          )}
        </ErrorMessage>
      </div>
    </>
  );
};

export default TextInput;
