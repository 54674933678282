import UserActionTypes from './user.type';

const INITIAL_STATE = null

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_CURRENT_USER:
      return  action.payload;
    default:
      return state;
  }
}

export default userReducer;